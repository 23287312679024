import { validateFields } from "./rules/checkAllFields";
import { validateWhitelist } from "./rules/checkWhitelist";
import { validateBarcodeAttributes } from "./rules/validateBarcodeAttributes";
import { validateBarcodeDate } from "./rules/checkBarcodeDate";
import { decryptBarcode } from "./decryptBarcode";
import { validateBarcodeFormat } from "./rules/checkBarcodeFormat";
import { validateGateAdmissionRule } from "./rules/validateAdmissionRules";
import { validateScanningTime } from "./rules/validateScanningTime";
import { validateAdmissionType } from "./rules/checkAdmissionType";
import { validateAdmissionCount } from "./rules/checkAdmissionCount";
import { validateSameDayAccess } from "./rules/checkSameDayAccess";
import { validateUniqueGate } from "./rules/checkUniqueGate";
import * as constants from "../constants/constants";

export const validate = async (param) => {
  let error;

  const validateBarcode = async (data, param) => {
    let foundBarcode;
    for (let index = 0; index < data.length; index++) {
      if (param.ticketId) {
        foundBarcode = data[index].barcodes.find(
          (barcode) => barcode.ticketId === param.ticketId
        );
      } else {
        foundBarcode = data[index].barcodes.find(
          (barcode) => barcode.barcode === param.barcode
        );
      }
      if (foundBarcode) {
        return foundBarcode;
      } else {
        if (param.barcode) {
          let decrypted = await decryptBarcode(
            param.barcode,
            data[index].encryptionKey
          );
          if (decrypted) {
            return decrypted;
          }
        }
      }
    }
    return foundBarcode;
  };

  // Check all fields validation Rule 1
  error = await validateFields(param);
  let admissionRun = false;
  if (!error) {
    // check override reason
    if (
      param.overrideReason &&
      param.overrideReason !== null &&
      param.overrideReason.length > 0
    ) {
      return { override: true };
    }
    const data = JSON.parse(localStorage.getItem("SYNC_DATA"));
    // Rule 3
    const barcode = await validateBarcode(data, param);
    constants.Validation.barcode = barcode ? barcode.barcode : param.barcode;
    if (!barcode || barcode.error) {
      return { error: "validate_barcode_invalid" };
    }
    constants.Validation.barcodeId = barcode._id ? barcode._id : null;
    // Rule 4
    const result = await retrieveObjectsFromLocalStorage(data, param, barcode);
    const barcodeTicketType =
      barcode.barcodeAttributes.find((attribute) => {
        return attribute.attributeType === "TICKET_TYPE";
      }) || null;
    const barcodeCategory =
      barcode.barcodeAttributes.find((attribute) => {
        return attribute.attributeType === "PRICE_CATEGORY";
      }) || null;
    if (!barcode.decrypted) {
      constants.Validation.ticketAttribute = barcodeTicketType;
      constants.Validation.category = barcodeCategory;
    } else {
      if (result.eventAttributes && result.eventAttributes.length > 0) {
        for (let i = 0; i < result.eventAttributes.length; i++) {
          if (
            result.eventAttributes[i].attributeType === "TICKET_TYPE" &&
            result.eventAttributes[i].attributeCode === barcodeTicketType.attributeId
          ) {
            constants.Validation.ticketAttribute = result.eventAttributes[i];
          }
          if (
            result.eventAttributes[i].attributeType === "PRICE_CATEGORY" &&
            result.eventAttributes[i].attributeCode === barcodeCategory.attributeId
          ) {
            constants.Validation.category = result.eventAttributes[i];
          }
          // Break if both attributes are found
          if (constants.Validation.ticketAttribute && constants.Validation.category) {
            break;
          }
        }
      } else {
        constants.Validation.ticketAttribute = null;
      }
    }
    if (result.error) {
      return { error: result.error };
    }
    // decrypted barcode always pass
    if (!barcode.decrypted) {
      // Rule 7
      error = await validateBarcodeFormat(barcode);
      if (error) return { error: error };
      error = await validateWhitelist(barcode);
      if (error) return { error: error };
      if (
        (barcode.validityStart && barcode.validityEnd) ||
        (barcode.validityStart !== null && barcode.validityEnd !== null)
      ) {
        const dateError = await validateBarcodeDate(barcode);
        if (dateError) return { error: dateError };
      }
    }

    // check get latest barcode status from local storage
    const barcodeAdmissionStatus = await retrieveOfflineScannedLocalStorage(
      param,
      true,
      false,
      -1
    );

    const barcodeStatus = {
      admissionType: barcodeAdmissionStatus
        ? barcodeAdmissionStatus.admissionType
        : null,
      admissionCount: barcodeAdmissionStatus
        ? barcodeAdmissionStatus.admissionCount
        : 0,
    };
    // check all admission rules
    for (let index = 0; index < result.admissionRules.length; index++) {
      constants.Validation.admissionRuleId = result.admissionRules[index]._id;
      if (result.admissionRules[index].status === 0) {
        continue;
      }
      admissionRun = true;
      constants.Validation.admissionRuleId = result.admissionRules[index]._id;
      constants.Validation.scanCheck = result.admissionRules[index].scanCheck;
      constants.Validation.admissionCount =
        result.admissionRules[index].admissionCount;
      constants.Validation.appResponseColor =
        result.admissionRules[index].appResponseColor;
      // validate admission rules
      let attributeError;
      if (barcode.barcodeAttributes && barcode.barcodeAttributes.length > 0) {
        attributeError = await validateBarcodeAttributes(
          result.admissionRules[index].admissionRuleCriteria,
          barcode.barcodeAttributes,
          result.session._id,
          error
        );
      }
      if (!attributeError || attributeError === null) {
        // check scanning time entry/exit
        const scanError = await validateScanningTime(
          result.admissionRules[index],
          param.admissionType,
          result.session,
          error
        );
        if (!scanError || scanError === null) {
          // check admission type with barcode status
          const admissionTypeError = await validateAdmissionType(
            barcodeStatus,
            param,
            result.admissionRules[index],
            error
          );

          if (!admissionTypeError || admissionTypeError === null) {
            const entrySuccess = await retrieveOfflineScannedLocalStorage(
              param,
              true,
              true
            );

            const admissionCountError = await validateAdmissionCount(
              entrySuccess == null ? 0 : entrySuccess.length,
              result.admissionRules[index],
              param.admissionType,
              error
            );

            if (!admissionCountError || admissionCountError === null) {
              // validate unique gate
              let uniqueGateError = null;
              if (
                param.admissionType === constants.AdmissionTypeIn &&
                result.admissionRules[index].uniqueGate &&
                result.admissionRules[index].ruleType !==
                  constants.AdmissionRuleType.SINGLE_ENTRY_EXIT
              ) {
                const lastBarcodeRecord =
                  await retrieveOfflineScannedLocalStorage(
                    param,
                    false,
                    true,
                    -1
                  );

                if (lastBarcodeRecord) {
                  uniqueGateError = await validateUniqueGate(
                    param.gate,
                    lastBarcodeRecord,
                    error
                  );
                }
              }

              if (!uniqueGateError || uniqueGateError === null) {
                // check same day access
                let sameDayAccessError = null;
                if (
                  param.admissionType === constants.AdmissionTypeIn &&
                  result.admissionRules[index].sameDayAccess &&
                  result.admissionRules[index].ruleType !==
                    constants.AdmissionRuleType.SINGLE_ENTRY_EXIT
                ) {
                  const lastBarcodeAdmitStatus =
                    await retrieveOfflineScannedLocalStorage(
                      param,
                      true,
                      true,
                      0
                    );

                  if (lastBarcodeAdmitStatus) {
                    sameDayAccessError = await validateSameDayAccess(
                      lastBarcodeAdmitStatus,
                      result.admissionRules[index],
                      error
                    );
                  }
                }
                if (!sameDayAccessError || sameDayAccessError === null) {
                  error = null;
                  break;
                } else {
                  error = sameDayAccessError;
                }
              } else {
                error = uniqueGateError;
              }
            } else {
              error = admissionCountError;
            }
          } else {
            error = admissionTypeError;
          }
        } else {
          error = scanError;
        }
        let gateAdmissionError;
        for (let i = 0; i < result.admissionRuleGates.length; i++) {
          gateAdmissionError = await validateGateAdmissionRule(
            param,
            result.admissionRuleGates[i],
            result.admissionRules[index],
            error
          );
          if (!gateAdmissionError || gateAdmissionError === null) {
            break;
          }
        }
        if (gateAdmissionError) {
          error = gateAdmissionError;
        }
      } else {
        error = attributeError;
      }
    }
    if (error) {
      return { error: error.type };
    }
  }
  return { admissionRun: admissionRun };
};

const retrieveOfflineScannedLocalStorage = async (
  param,
  checkGate,
  checkIsEntry,
  position = null
) => {
  const offline_batch = JSON.parse(localStorage.getItem("OFFLINE_BATCH")) || [];
  const offline_batch_process =
    JSON.parse(localStorage.getItem("OFFLINE_BATCH_PROCESS")) || [];

  const offline_data = await offline_batch.concat(offline_batch_process);

  if (!offline_data || offline_data.length === 0) return null;

  const bypass = true;

  const filtered_data = await offline_data.filter((data) => {
    let validateResult = data.scanResult === 1;
    let validateBarcode = param.barcode === data.barcode;
    let validateGate = checkGate ? param.gate === data.gateId : bypass;
    let validateIsEntry = checkIsEntry
      ? constants.AdmissionTypeIn === data.admissionType
      : bypass;

    return validateResult && validateBarcode && validateGate && validateIsEntry;
  });

  if (!filtered_data || filtered_data.length === 0) return null;

  if (position == null) return filtered_data;

  const size = filtered_data.length;
  const index = position < 0 ? position + size : position; // -1 + (size) = last-data | 0 = first-data

  return filtered_data[index];
};

const retrieveObjectsFromLocalStorage = async (data, param, barcode) => {
  let admissionRuleGates = [];
  let session;
  let eventAttributes;
  let admissionRules = [];
  for (let index = 0; index < data.length; index++) {
    if (data[index].eventCode === barcode.eventCode) {
      eventAttributes = data[index].eventAttributes;
      for (let i = 0; i < data[index].admissionRules.length; i++) {
        for (
          let x = 0;
          x < data[index].admissionRules[i].admissionRuleGate.length;
          x++
        ) {
          if (
            data[index].admissionRules[i].admissionRuleGate[x].gateTypeId ===
            param.gate
          ) {
            admissionRuleGates.push(
              data[index].admissionRules[i].admissionRuleGate[x]
            );
          }
        }
      }

      if (
        !Array.isArray(admissionRuleGates) ||
        admissionRuleGates.length === 0
      ) {
        return { error: "validate_wrong_gate" };
      }
      for (let i = 0; i < data[index].sessions.length; i++) {
        if (data[index].sessions[i].sessionCode === barcode.sessionCode) {
          session = data[index].sessions[i];
          break;
        }
      }
      if (!session || session === null) {
        return { error: "validate_session_invalid" };
      }
      for (let i = 0; i < data[index].admissionRules.length; i++) {
        if (data[index].admissionRules[i].eventId === data[index]._id) {
          admissionRules.push(data[index].admissionRules[i]);
        }
      }
      if (admissionRules.length <= 0) {
        return { error: "validate_admissionRule_not_found" };
      }
      return { eventAttributes, admissionRules, admissionRuleGates, session };
    }
  }
  return { error: "validate_event_invalid" };
};
