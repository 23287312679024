import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faClipboard,
  faCog,
  faExpand,
  faSignOutAlt,
  faSyncAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import HealthCheck from '../healthCheck/HealthCheck';
import Auth from '../../controller/authHandler/AuthHandler';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import ValidatorModeHandler from '../../controller/validatorModeHandler/ValidatorModeHandler';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import VoucherModeHandler from '../../controller/voucherModeHandler/voucherModeHandler';
import Popup from "../popup/Popup";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: 0,
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  appBarColor: {
    backgroundColor: '#BF0603',
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  margin: {
    margin: 6,
  },
  color: {
    color: '#888888',
  },
  hide: {
    display: 'none',
  },
}));

const AppBarMenu = function () {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [state] = useState({
    bottom: false,
  });
  const {
    token,
    setToken,
    appMode,
    setAppMode,
    validatorMode,
    setValidatorMode,
    setVoucherMode,
    connection,
    userPermissions,
    setShowPinModal,
    setToSettings,
    setToOverride,

    setCorrectPin
  } = useContext(AppContext);
  const [title, setTitle] = useState('');
  const [modeTitle, setModeTitle] = useState('');

  const menuListItem = [
    { name: 'Scan', link: '/', icon: faExpand },
    { name: 'Report', link: '/report', icon: faClipboard },
    { name: 'Setting', link: '/setting', icon: faCog },
    { name: 'Offline Batch', link: '/sync', icon: faSyncAlt },
  ];
  const hideHeaderList = [
    '/pairScanner',
    '/login',
    '/sync',
    '/event',
    '/override',
    '/viewAudit',
    '/barcodeAudit',
    '/barcodeAuditManualEntry',
    '/setting',
    '/manualEntry',
    '/validatorEntry',
    '/offlineBatchSync',
    '/offlineBatchSyncDetails',
    '/report',
    '/reportDetails',
    '/gateReport',
    '/gateReportDetails',
    '/scanCountReport',
    '/scanCountReportDetails',
    '/reset',
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const [hideClass, setHideClass] = useState();
  const [showStaffAccessInfo, setShowStaffAccessInfo] = useState(false);
  const open = Boolean(anchorEl);
  const titleRef = useRef();
  const timerRef = useRef(null);

  useEffect(() => {
    const cookieAppMode = AppModeHandler.appMode()
    if ((cookieAppMode === constants.AppModeOnline || cookieAppMode === undefined )&& connection) {
      setAppMode(constants.AppModeOnline);
      AppModeHandler.setAppMode(constants.AppModeOnline);
      setModeTitle(t('scan_headerText_1'));
    } else if (cookieAppMode === constants.AppModeOffline || !connection && cookieAppMode!== constants.AppModeEmergency) {
      setAppMode(constants.AppModeOffline);
      setModeTitle(t('scan_headerText_2'));
    } else {
      // emergency mode
      setModeTitle(t('scan_headerText_3'));
    }
    setAppMode(cookieAppMode);
    setValidatorMode(ValidatorModeHandler.validatorMode());
    setVoucherMode(VoucherModeHandler.voucherMode());
  }, [AppModeHandler.appMode(), ValidatorModeHandler.validatorMode(), VoucherModeHandler.voucherMode(), connection]);

  useEffect(() => {
    for (let i = 0; i < menuListItem.length; i += 1) {
      if (location.pathname === menuListItem[i].link) {
        if (menuListItem[i].name !== 'Scan' && menuListItem[i].name !== 'Setting') {
          setTitle(menuListItem[i].name);
        } else {
          setTitle('');
        }
      }
    }

    if (hideHeaderList.includes(location.pathname)) {
      setHideClass(` ${classes.hide}`);
    } else {
      setHideClass();
    }
  }, [location.pathname, title]);

 useEffect(() => {
   if (showStaffAccessInfo) {
     if (timerRef.current) clearTimeout(timerRef.current);

     timerRef.current = setTimeout(() => {
       setShowStaffAccessInfo(false);
     }, Configs.GATE_REPORT.STAFF_DENY_ENTRY_DURATION);

     return () => clearTimeout(timerRef.current);
   }
 }, [showStaffAccessInfo]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setAnchorEl(titleRef.current);
  }

  const titleHandler = (name) => {
    setTitle(name);
  };

  const accountLogoutHandler = () => {
    Auth.removeToken();
    setAppMode(false);
    setValidatorMode(false);
    setVoucherMode(false);
    setToken(null);
    setAnchorEl(null);
  };

  const toggleDrawer = () => {
    const settingsPermission = userPermissions.find(
      perm => perm.feature === "advanced-access-settings"
    );
    const pinRequired = settingsPermission?.pin
    if(pinRequired){
      setCorrectPin(settingsPermission?.pin);
      setShowPinModal(true)
      setToSettings(true)
    } else {
    history.push(Configs.SETTING.PATH);
    }
  };

  const overrideHandler = () => {
    const overridePermission = userPermissions.find(
      perm => perm.feature === "advanced-override"
    );
    
    const pinRequired = overridePermission?.pin
    if(pinRequired){
      setCorrectPin(overridePermission?.pin);
      setShowPinModal(true)
      setToOverride(true)
    } else {
      if (document.querySelector('.scan-override').classList.contains('active')) {
        history.push(Configs.OVERRIDE.PATH);
      }
    }
  };

  const closeModal = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    setShowStaffAccessInfo(false);
  };

  const list = () => (
    <div role='presentation' onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <List>
        {menuListItem.map((item) => (
          <Link
            to={item.link}
            key={item.name}
            onClick={() => titleHandler(item.name)}
          >
            <ListItem button key={item.name}>
              <FontAwesomeIcon icon={item.icon} />
              <ListItemText primary={item.name} className={classes.color} />
            </ListItem>
          </Link>
        ))}
        {token && (
          <ListItem button key='Logout' onClick={accountLogoutHandler}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <ListItemText primary='Logout' className={classes.color} />
          </ListItem>
        )}
      </List>
    </div>
  );

  return (
    <div className={classes.root + hideClass}>
      {showStaffAccessInfo
        ? <Popup
          icon={faExclamationCircle}
          onClose={closeModal}
          title={t('setting_gateStaffErrorTitle')}
          />  
      : null}
      <AppBar position='fixed' className={classes.appBarColor}>
        <Toolbar>
          <IconButton
            onClick={toggleDrawer}
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='menu'
          >
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <Typography variant='h6' className={classes.title} onClick={handleOpen}>
            <div
              ref={titleRef} 
              className={`scan-color ${
                appMode === constants.AppModeOnline ? '' : 'red'
              }`}
            />
            {token && title}
            {title.length <= 0 && modeTitle}
          </Typography>
            <button
              type='button'
              className='scan-override'
              onClick={overrideHandler}
            >
              {t('scan_headerOverride')}
            </button>
          <HealthCheck
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        </Toolbar>
      </AppBar>
      <Drawer anchor='bottom' open={state.bottom} onClose={toggleDrawer}>
        {list('bottom')}
      </Drawer>
    </div>
  );
};

export default AppBarMenu;
