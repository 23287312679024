import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import "../../styles/pinModal.css";
import { AppContext } from '../../context/appContext';

const PinModal = function(props) {
    const { validatePin, setPin, pinError, pin } = props;
    const [showPassword, setShowPassword] = useState(false);
    
    const {
      setShowPinModal
    } = useContext(AppContext);

    const onClose = function(){
      setShowPinModal(false)
    }

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
        <div className='pin-modal-backdrop'>
            <div className='pin-modal'>
                <div className='pin-modal-header'>
                    <h2 className='pin-modal-title'>Enter PIN Number</h2>
                    <button className='pin-modal-close' onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className='pin-input-container'>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        placeholder='Enter Pin Number'
                        className='pin-input'
                    />
                    <button 
                      type='button'
                      className='password-toggle'
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </button>
                    {pinError && <p className='pin-error-message'>{pinError}</p>}
                </div>
                <button className='pin-submit-button' onClick={validatePin}>
                    Confirm
                </button>
            </div>
        </div>
    );
};

export default PinModal;