import React, { useContext, useState, useEffect, useRef } from "react";
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faExchangeAlt, faSyncAlt, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/appContext';
import PairScannerNameHandler from '../../controller/authHandler/PairScannerNameHandler';
import AppModeHandler from '../../controller/appModeHandler/AppModeHandler';
import ValidatorModeHandler from '../../controller/validatorModeHandler/ValidatorModeHandler';
import Auth from '../../controller/authHandler/AuthHandler';
import GateHandler from '../../controller/eventHandler/GateHandler';
import EventHandler from '../../controller/eventHandler/EventHandler';
import Configs from '../../Configs';
import * as constants from '../../constants/constants';
import '../../styles/setting.css';
import Btn from '../button/Button';
import ScannerUserHandler from '../../controller/authHandler/ScannerUserHandler';
import VoucherModeHandler from '../../controller/voucherModeHandler/voucherModeHandler';
import ScanInHandler from '../../controller/scanHandler/ScanInHandler';
import { isLatestConfigAvailable } from '../../services';
import Popup from "../popup/Popup";
import { useErrorHanlder } from "../../context/errorContext";

const Setting = function () {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const {
    appMode,
    setAppMode,
    validatorMode,
    setValidatorMode,
    voucherMode,
    setVoucherMode,
    scannerUser,
    scannerUserRole,
    setConnection,
    setToken,
    isSingleEventGate,
    connection,
  } = useContext(AppContext);

  const [scannerName] = useState(PairScannerNameHandler.getScannerName());
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [isOnlineButtonDisabled, setIsOnlineButtonDisabled] = useState(false);
  const [showLatestConfig, setShowLatestConfig] = useState(false);
  const [showStaffAccessInfo, setShowStaffAccessInfo] = useState(false);
  const timerRef = useRef(null);
  const avatarName = scannerUser.substring(0, 2);
  const { showErrorNotification } = useErrorHanlder();

  useEffect(() => {
    const ele = document.querySelector(`.setting-${appMode}`);
    if (ele !== null) {
      const modeBtn = document.querySelectorAll('.setting-button');
      [].forEach.call(modeBtn, (el) => {
        if (el.classList.contains(`setting-${appMode}`)) {
          el.classList.add('active');
        } else {
          el.classList.remove('active');
        }
      });

      ele.classList.add('active');

      if (appMode === constants.AppModeOnline) {
        document.querySelector('.scan-color').classList.remove('red');
      } else {
        document.querySelector('.scan-color').classList.add('red');
      }
    }
    ele.classList.add('active');

  }, [appMode, validatorMode, voucherMode]);
    
  useEffect(() => {
    const validatorModeBtn = document.querySelectorAll('.validator-button');
    if (typeof (validatorMode) == "undefined" && validatorMode !== constants.ValidatorModeOff) {
      setValidatorMode(constants.ValidatorModeOff);
    }
    [].forEach.call(validatorModeBtn, (el) => {
      if (el.classList.contains(`validator-${validatorMode}`)) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });
  }, [validatorMode]);

  useEffect(() => {
    const voucherModeBtn = document.querySelectorAll('.voucher-button');
    if (typeof (voucherMode) == "undefined") {
      setVoucherMode(false);
    }
    [].forEach.call(voucherModeBtn, (el) => {
      if (el.classList.contains(`voucher-${voucherMode ? 'on' : 'off'}`)) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });
  }, [voucherMode]);

  useEffect(() => {
    setIsOnlineButtonDisabled(!connection);
  }, [connection])

  useEffect(() => {
    if (showStaffAccessInfo) {
      if (timerRef.current) clearTimeout(timerRef.current);

      timerRef.current = setTimeout(() => {
        setShowStaffAccessInfo(false);
      }, Configs.GATE_REPORT.STAFF_DENY_ENTRY_DURATION);

    }

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [showStaffAccessInfo]);

  const handleResetConfirm = () => {
    setShowResetDialog(true);
  };

  const handleCancelReset = () => {
    setShowResetDialog(false);
  };

  const handleConfirmReset = () => {
    setShowResetDialog(false);
    history.push(Configs.RESET_DEVICE.PATH);
  };

  // toggle emergency mode of application
  const modeChange = (mode) => {
    if (
      scannerUserRole === constants.StaffScannerUserRole &&
      mode === constants.AppModeEmergency
    ) {
      setShowStaffAccessInfo(true);
      return;
    }
    setAppMode(mode);
    AppModeHandler.setAppMode(mode);
  };

  const validatorModeChange = (validatorMode) => {
    if (scannerUserRole === constants.StaffScannerUserRole) {
      setShowStaffAccessInfo(true);
      return;
    }
    setValidatorMode(validatorMode);
    ValidatorModeHandler.setValidatorMode(validatorMode);
  };

  const voucherModeChange = (mode) => {
    setVoucherMode(mode);
    VoucherModeHandler.setVoucherMode(mode);
  };

  const eventLinkHandler = () => {
    // if (isSingleEventGate) {
    // history.push(Configs.SCAN.PATH);
    // } else {
      history.push(Configs.EVENT.PATH, { from: location.pathname });
    // }
  };

  const logoutHandler = () => {
    // Auth.removeToken();
    setConnection(true);
    Auth.setToken(constants.GuestJWT(Auth.getSecret()));
    GateHandler.removeGateInfo();
    GateHandler.removeGateNameInfo();
    EventHandler.removeEventInfo();
    EventHandler.removeEventNameInfo();
    AppModeHandler.removeAppMode();
    ValidatorModeHandler.removeValidatorMode();
    VoucherModeHandler.removeVoucherMode();
    ScannerUserHandler.removeScannerUser();
    ScanInHandler.setIsScanInInfo(true)
    let keys = Object.keys(localStorage);
    keys.forEach((key) => {
      if (key.includes('EVENT_') || key.includes('SESSION_')) {
        localStorage.removeItem(key);
      }
    });
    setAppMode(false);
    setValidatorMode(false);
    setVoucherMode(false);
    setToken(null);
  };

  const onSuccessCallback = (response) => {
    setShowLatestConfig(response?.isLatestConfigAvailable)
  };

  const onErrorCallback = (err, status, message) => {
    showErrorNotification({message: `${status}: ${message}` || err});
  }

  useEffect(() => {
    isLatestConfigAvailable(onSuccessCallback, onErrorCallback);
  }, []);

const closeModal = () => {
  if (timerRef.current) clearTimeout(timerRef.current);
  setShowStaffAccessInfo(false);
};

  const handleFetchLatestConfig = () => {
    history.push(Configs.SYNC.PATH, { from: location.pathname });
  }

  return (
    <div className='setting-container'>
      {showStaffAccessInfo
      ? <Popup
          icon={faExclamationCircle}
          onClose={closeModal}
          title={t("setting_gateStaffErrorTitle")}
          />
        
      : null}
      <div className='setting-top'>
        <Link to={Configs.SCAN.PATH}>
          <div className='back-btn'>{t('setting_backText')}</div>
        </Link>
        <div className='event-details' />
        <div
          className='setting-logout'
          role='button'
          tabIndex='0'
          onKeyPress={logoutHandler}
          onClick={logoutHandler}
        >
          {t('setting_logoutText')}
        </div>
      </div>
      <div className='scanner-info'>
        <div className='profile-avatar'>{avatarName}</div>
        <p className='profile-username'>{scannerUser}</p>
        <p className='profile-scannername'>
          {t('setting_avatarScannerTitle')}
          {scannerName}
        </p>
      </div>
      <div className='mode-container'>
        <p className='setting-title'>{t('setting_selectMode')}</p>
        <div className='mode-selection'>
          <Button
            disabled={isOnlineButtonDisabled}
            disableElevation
            variant='contained'
            className={`setting-button setting-${constants.AppModeOnline}`}
            onClick={() => modeChange(constants.AppModeOnline)}
          >
            {t('setting_mode_1')}
          </Button>
          <Button
            disableElevation
            variant='contained'
            className={`setting-button setting-${constants.AppModeOffline}`}
            onClick={() => modeChange(constants.AppModeOffline)}
          >
            {t('setting_mode_2')}
          </Button>
          <Button
            disableElevation
            className={ scannerUserRole === constants.StaffScannerUserRole 
                ? 'disabled-button-emergency' 
                : `setting-button emergency-button setting-${constants.AppModeEmergency}`
            }
            variant='contained'
            onClick={() => modeChange(constants.AppModeEmergency)}
          >
            {t('setting_mode_3')}
          </Button>
        </div>
      </div>
      <div className='mode-container'>
        <p className='setting-title'>{t('setting_validatorMode')}</p>
        <div className='mode-selection'>
          <Button
            disableElevation
            variant='contained'
            className={
              scannerUserRole === constants.StaffScannerUserRole ? 'disabled-button-validator' :
                `validator-button validator-${constants.ValidatorModeOff}`
            }
            onClick={() => validatorModeChange(constants.ValidatorModeOff)}
          >
            {t('setting_off')}
          </Button>
          <Button
            disableElevation
            variant='contained'
            className={
              scannerUserRole === constants.StaffScannerUserRole ? 'disabled-button-validator' :
                `validator-button validator-${constants.ValidatorModeOn}`
            }
            onClick={() => validatorModeChange(constants.ValidatorModeOn)}
          >
            {t('setting_on')}
          </Button>
        </div>
      </div>
      <div className='mode-container'>
        <p className='setting-title'>{t('setting_voucherMode')}</p>
        <div className='mode-selection'>
          <Button
              disableElevation
              variant='contained'
              className={`voucher-button voucher-off`}
              onClick={() => voucherModeChange(false)}
          >
            {t('setting_off')}
          </Button>
          <Button
              disableElevation
              variant='contained'
              className={`voucher-button voucher-on`}
              onClick={() => voucherModeChange(true)}
          >
            {t('setting_on')}
          </Button>
        </div>
      </div>
      <div className='setting-content'>
        <div onClick={handleFetchLatestConfig} role='button'>
          <FontAwesomeIcon icon={faSyncAlt}/>
          <span className='setting-fetchLatestConfig'>
            {t("setting_fetchLatestConfig")}
            {showLatestConfig ? <FontAwesomeIcon icon={faExclamationCircle} style={{color: "#FFD43B"}} /> : null}
          </span>
        </div>
        <div
          onClick={eventLinkHandler}
          onKeyPress={eventLinkHandler}
          role='button'
          tabIndex='0'
        >
          <FontAwesomeIcon icon={faReceipt} />
          <span>{t('setting_gateTitle')}</span>
        </div>
        <Link to={Configs.BARCODE_AUDIT.PATH}>
          <div>
            <FontAwesomeIcon icon={faReceipt} />
            <span>{t('setting_barcodeAuditTitle')}</span>
          </div>
        </Link>
        <Link to={Configs.REPORT.PATH}>
          <div>
            <FontAwesomeIcon icon={faReceipt} />
            <span>{t('setting_reportTitle')}</span>
          </div>
        </Link>
        <Link to={Configs.GATE_REPORT.PATH}>
          <div>
            <FontAwesomeIcon icon={faReceipt} />
            <span>{t('setting_gateReportTitle')}</span>
          </div>
        </Link>
        <Link to={Configs.SCAN_COUNT_REPORT.PATH}>
          <div>
            <FontAwesomeIcon icon={faReceipt} />
            <span>{t('setting_scanCountReportTitle')}</span>
          </div>
        </Link>
        <Link to={Configs.OFFLINE_BATCH_SYNC.PATH}>
          <div>
            <FontAwesomeIcon icon={faExchangeAlt} />
            <span>{t('setting_offlineBatchSyncTitle')}</span>
          </div>
        </Link>
      </div>
      <div className='reset' onClick={handleResetConfirm}>
        {t('setting_resetText')}
      </div>
      {showResetDialog && (
        <div className='confirm-dialog unpair'>
          <p>{t('setting_reset_confirmText')}</p>
          <div className='btn-group unpair'>
            <Btn
              cName='dialog-cancel unpair'
              disable={false}
              funct={handleCancelReset}
              text={t('manualEntry_btnCancel')}
            />
            <Btn
              cName='dialog-confirm unpair'
              disable={false}
              funct={handleConfirmReset}
              text={t('manualEntry_btnConfirm')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Setting;
